$gc: rgb(255, 251, 0);
$gc_dark_back: rgb(17, 18, 26);
$gc_dark: rgb(255, 251, 0);
.TopBar {
    //background-color: white;
    font-size: calc(10px + 2vmin);
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgb(255, 255, 255) 0%,
        $gc 100%
    );
    background: -webkit-linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgb(255, 255, 255) 0%,
        $gc 100%
    );
    background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgb(255, 255, 255) 0%,
        $gc 100%
    );

    width: 100%;
    display: flex;

    flex-direction: row;

    justify-content: space-between;

    position: fixed;
    top: 0;

    .left {
        flex: 0.8;

        justify-content: flex-end;

        //padding-left: 25px;

        left: 0;

        display: flex;

        flex-direction: row;

        img {
            cursor: pointer;
        }

        .text {
            display: flex;
            flex-direction: column;

            margin: auto;
            margin-left: 0;

            h3 {
                margin: auto;
                padding-left: 60px;
                flex: 0.5;
                margin-left: 0;
            }

            p {
                flex: 0.5;
                margin: auto;
                padding-left: 25px;
                font-size: medium;
            }
        }
    }

    .right {
        flex: 0.2;
    }
}

@media only screen and (max-width: 500px) {
    p {
        margin-left: 0px !important;
    }
}

@media (prefers-color-scheme: dark) {
    .TopBar {
        background: rgb(17, 18, 26);
        background: -moz-linear-gradient(
            270deg,
            $gc_dark_back 0%,
            $gc_dark_back 0%,
            $gc 100%
        );
        background: -webkit-linear-gradient(
            270deg,
            $gc_dark_back 0%,
            $gc_dark_back 0%,
            $gc 100%
        );
        background: linear-gradient(
            270deg,
            $gc_dark_back 0%,
            $gc_dark_back 0%,
            $gc 100%
        );

        .left {
            .text {
                h3 {
                    color: rgb(0, 0, 0);
                }

                p {
                    color: rgb(0, 0, 0);
                }
            }
        }
    }
}
