.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}


