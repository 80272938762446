.ArticleBox {
    display: flex;
    margin-top: 100px;

    a {
        text-decoration: none;
        color: rgb(10, 50, 255);
    }

    a:hover {
        text-decoration: none;
        color: rgb(10, 50, 255);
    }

    a:visited {
        text-decoration: none;
        color: rgb(10, 50, 255);
    }

    .Article {
        top: 70px;
        display: flex;
        flex-direction: column;
        background-color: rgba(110, 110, 110, 0.288);
        width: 50%;
        margin: auto;
        min-height: 100vh;

        padding-left: 20px;
        padding-right: 20px;

        h1 {
            font-size: xx-large;
        }

        h2 {
            margin: auto;
            margin-left: 0;
            font-size: x-large;
        }

        p {
            font-size: x-large;
        }

        .date {
            font-size: small;
            font-weight: bold;
            list-style-type: none;
        }
        .article_image {
            padding-bottom: 20px;
            align-self: center;
            img {
                margin: 0;
            }
            .image_text {
                list-style: none;
                font-size: x-small;
            }
        }

    }
}

@media (prefers-color-scheme: dark) {
    .ArticleBox {

        a {
            text-decoration: none;
            color: rgb(10, 145, 255);
            transition: all .3s ease-in-out;
        }
    
        a:hover {
            text-decoration: underline;
            color: rgb(10, 145, 255);
        }
    
        a:visited {
            text-decoration: none;
            color: rgb(10, 145, 255);
        }
    

        background-color: rgb(17, 18, 26);    
        .Article {
            color: rgb(233, 233, 233);
            background-color: rgb(43, 45, 63);

        }
        
    }
}

@media only screen and (max-width: 550px) {
    .article {
        margin-top: 0px !important;
        width: 100% !important;
    }
}
